import { createContext, ReactNode, useState } from 'react';

import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';

interface Props {
    children: ReactNode;
}

export enum BaseModalType {
    Accept = 'Accept',
    AndroidConfig = 'AndroidConfig',
    Config = 'Config',
    Confirmation = 'Confirmation',
    Create = 'Create',
    Default = 'Default',
    Edit = 'Edit',
    Iframe = 'Iframe',
    iOSConfig = 'iOSConfig',
}

type ModelState = {
    [key in BaseModalType | CustomNodeTypes]?: { show: boolean };
};

interface State {
    state: ModelState;
    openModal?: (key: BaseModalType | CustomNodeTypes) => void;
    closeModal?: (key: BaseModalType | CustomNodeTypes) => void;
}

const ProviderState: ModelState = Object.values(BaseModalType).reduce(
    (state: ModelState, value: BaseModalType) => {
        state[value] = { show: false };
        return state;
    },
    {},
);

export const BaseModalContext = createContext<State>({ state: ProviderState });

export const BaseModalProvider = ({ children }: Props) => {
    const [state, setState] = useState<ModelState>(ProviderState);

    const openModal = (contextKey: BaseModalType | CustomNodeTypes) =>
        setState((currentState) => ({ ...currentState, [contextKey]: { show: true } }));

    const closeModal = (contextKey: BaseModalType | CustomNodeTypes) =>
        setState((currentState) => ({ ...currentState, [contextKey]: { show: false } }));

    return (
        <BaseModalContext.Provider value={{ state, openModal, closeModal }}>
            {children}
        </BaseModalContext.Provider>
    );
};
