import { useContext } from 'react';

import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalContext, BaseModalType } from '@admin/organisms/BaseModal/BaseModalProvider';

export const useModalContext = (contextKey: BaseModalType | CustomNodeTypes) => {
    const { state, openModal, closeModal } = useContext(BaseModalContext);

    const handleOpenModal = () => (openModal ? openModal(contextKey) : undefined);
    const handleCloseModal = () => (closeModal ? closeModal(contextKey) : undefined);

    return { state: state[contextKey], openModal: handleOpenModal, closeModal: handleCloseModal };
};
